import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router'
import store from './store'
import axios from 'axios'

import 'ant-design-vue/dist/antd.css'
Vue.config.productionTip = false
Vue.use(Antd)
Vue.prototype.$axios=axios
axios.defaults.baseURL='/api'
axios.defaults.headers.post['Content-Type']='application/json'
import AMap from 'vue-amap';
Vue.use(AMap);
AMap.initAMapApiLoader({
  key: "869c7ce2b28f55b9b8b2c73c84488019", // "key"
  plugin: [
    'AMap.Autocomplete', // 输入提示插件
    'AMap.PlaceSearch', // POI搜索插件
    'AMap.Scale', // 右下角缩略图插件 比例尺
    'AMap.OverView', // 地图鹰眼插件
    'AMap.ToolBar', // 地图鹰眼插件
    'AMap.MapType', // 地图类型切换插件，可用于切换卫星地图
    'AMap.PolyEditor', // 多边形编辑器，绘制和编辑折现
    'AMap.CircleEditor', // 圆编辑插件
    'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
    'AMap.Geocoder' // 逆地理编码,通过经纬度获取地址所在位置详细信息
    // 按实际需求添加插件
  ],
  v: '1.4.4'
})

router.beforeEach((to, from, next) => {
  // to 即将进入的路由
  // from 在哪个路由进入的
  // next 放行
  //   console.log(to); //打印的是页面要跳转到的路由,例如：它下面的path：为"/login"
  let token = localStorage.getItem("token") || ""; //在本地存储中获取token
  if (token) {
    //判断是否有token
    next();
  } else {
    //在没有token的前提下，to下面的path是否为/login，如果不是则页面跳转到登录页面
    if (to.path == "/login") {
      next();
    } else {
      next({ path: "/login" }); //跳转页面到login页
    }
  }
});

/*高德的安全密钥
window._AMapSecurityConfig = {
	securityJsCode:"这里填写得到的安全密钥" // "安全密钥",
}*/

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
