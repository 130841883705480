import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    name: "控制台",
    path: "/",
    component: () => import("../views/Config/Console/index.vue"),
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../views/Home/Login/index.vue"),
  },
  {
    name: "基础信息",
    path: "/config/index",
    component: () => import("../views/Config/Index/index.vue"),
  },
  {
    name: "清除缓存",
    path: "/config/cache",
    component: () => import("../views/Config/Cache/index.vue"),
  },
  {
    name: "轮播管理",
    path: "/config/ad",
    component: () => import("../views/Config/Ad/index.vue"),
  },
  {
    name: "数据字典",
    path: "/config/attr",
    component: () => import("../views/Config/Attr/index.vue"),
  },
  {
    name: "城市设置",
    path: "/config/city",
    component: () => import("../views/Config/City/index.vue"),
  },
  {
    name: "消息管理",
    path: "/config/msg",
    component: () => import("../views/Config/Msg/index.vue"),
  },
  {
    name: "系统文章",
    path: "/config/info",
    component: () => import("../views/Config/Info/index.vue"),
  },
  {
    name: "优惠券设置",
    path: "/config/coupon",
    component: () => import("../views/Config/Coupon/index.vue"),
  },
  {
    name: "用户管理",
    path: "/config/admin",
    component: () => import("../views/Config/Admin/index.vue"),
  },
  {
    name: "过去病史",
    path: "/set1",
    component: () => import("../views/Set/Set1/index.vue"),
  },
  {
    name: "家族病史",
    path: "/set2",
    component: () => import("../views/Set/Set2/index.vue"),
  },
  {
    name: "项目清单",
    path: "/set3",
    component: () => import("../views/Set/Set3/index.vue"),
  },
  {
    name: "体检报告模板",
    path: "/set4",
    component: () => import("../views/Set/Set4/index.vue"),
  },
  {
    name: "员工账号",
    path: "/set5",
    component: () => import("../views/Set/Set5/index.vue"),
  },
  {
    name: "下发日志",
    path: "/log",
    component: () => import("../views/Log/Index/index.vue"),
  },
  {
    name: "用户档案",
    path: "/user",
    component: () => import("../views/User/Index/index.vue"),
  },
  {
    name: "info",
    path: "/info",
    meta: {
      hideInMenu: true,
      title: '用户详情',
      notCache: true,
    },
    component: () => import("../views/User/Index/info.vue"),
  },
  
  {
    name: "算法设置",
    path: "/target1",
    component: () => import("../views/Target/Target1/index.vue"),
  },
  {
    name: "算法设置",
    path: "/target2",
    component: () => import("../views/Target/Target2/index.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
