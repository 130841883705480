import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id:0,
  	name:'',
  	admin:0,
  	group:0,
  	auth:[], 
  	city:[],
		tag:[],
		house:[],
		year:[],
		token:''
  },
  mutations: {
		login(state, params){
			state.id = params.id
			state.name = params.name
			state.admin = params.admin
			state.group = params.group
			state.auth = params.auth
		},
		getToken(state, params){
			console.log(state,params);
			// state.id = params
		},
		set_city(state, params){
			state.city = params
		},
		set_auth(state, params){
			state.auth = params
		},
		set_tag(state, params){
			state.tag = params
		},
		set_house(state, params){
			state.house = params
		},
		set_year(state, params){
			state.year = params
		},
  },
  actions: {

  },
  modules: {
  }
})
