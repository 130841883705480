import req from './http.js'

export const login = params => req('post', '/passport/login', params)
export const getInfo = params => req('post', '/sys/admin/getInfo', params)
export const personlist = params => req('post', '/user/archives/list', params)
export const binglist = params => req('post', '/sys/dictionary/list', params)
export const delete_bing = params => req('post', '/sys/dictionary/delete', params)
export const add_bing = params => req('post', '/sys/dictionary/add', params)
export const qingdan_list = params => req('post', '/aaaaaa', params)
export const modal_list = params => req('post', '/sys/template/list', params)
export const update_modal = params => req('post', '/sys/template/update', params)
export const listSendToInfo = params => req('post', '/user/archives/listSendToInfo', params)
export const roleList = params => req('post', '/pms/admin/roleList', params)
export const userlist = params => req('post', '/sys/admin/list', params)
export const userupdate = params => req('post', '/sys/admin/update', params)
export const joblist = params => req('post', '/pms/post/list', params)
export const loglist = params => req('post', '/send/log/list', params)
export const calclist = params => req('post', '/calc/config/list', params)
export const addlist = params => req('post', '/calc/config/add', params)
export const delList = params => req('post', '/calc/config/delete', params)
export const updateList = params => req('post', '/calc/config/update', params)
export const itemlist = params => req('post', '/calcConfigItem/config/list', params)
export const itemadd = params => req('post', '/calcConfigItem/config/add', params)
export const edititem = params => req('post', '/calcConfigItem/config/update', params)
export const delitem = params => req('post', '/calcConfigItem/config/delete', params)
export const addMoban = params => req('post', '/sys/template/add', params)
export const deleteMoban = params => req('post', '/sys/template/delete', params)
export const home = params => req('post', '/sys/admin/home', params)
export const userdetail = params => req('post', '/user/archives/detail', params)
export const sendLog = params => req('post', '/user/archives/sendLog', params)










