import axios from "axios";

// 创建axios的一个实例  baseURL: process.env.VUE_APP_BASE_API,
var instance = axios.create({
  baseURL: 'https://admin.shikepeidai.cn/adminapi',
  // baseURL:'https://sktj.wh66.cn',
  // baseURL: "https://adminshikepeidai.cn/admin_api",
  timeout: 6000,

});
let config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Token": localStorage.getItem("token"),
  },
};
//  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// 一、请求拦截器 忽略
instance.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("token");
    // if (token !== "undefined") {
      config.headers.authorization = token;
      config.headers.Token = token;
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// 二、响应拦截器 忽略
instance.interceptors.response.use(
  function (response) {
   //  if (response.data.status == 401) localStorage.clear();
    return response.data;
  },
  function (error) {
    console.log(
      error == "Error: Request failed with status code 401",
      typeof error
    );
    if (error == "Error: Request failed with status code 401") {
      // localStorage.clear();
    }
    return Promise.reject(error);
  }
);
export default function (method, url, data = null) {
  method = method.toLowerCase();
  if (method === "post") {
    const formData = new FormData();
    // 将传入的data对象中的键值对数据添加到formData中
    for (const key in data) {
      if (key in data) {
        // Do something with the property
        formData.append(key, data[key]);
      }
    }
    return instance.post(url, formData, config);
  } else if (method === "get") {
    return instance.get(url, { params: data });
  } else if (method === "delete") {
    return instance.delete(url, { params: data });
  } else if (method === "put") {
    return instance.put(url, data);
  } else {
    console.error("未知的method" + method);
    return false;
  }
}
