<template>
	<div id="app">
		<Header :item="menu" v-if="show" ></Header>
		<div class="page">
			<a-locale-provider :locale="locale">
				<router-view />
			</a-locale-provider>
		</div>
	</div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import Header from './views/Layout/Header.vue'
import { menu } from "./api/index.js";
export default {
	components: {
		Header
	},
	beforeCreate() {
		
	},
	mounted() {
		// let token = localStorage.getItem('token')
		// if (token) {
		// 	// this.$store.commit('login',{auth:JSON.parse(auth),id:localStorage.getItem('id'),name:localStorage.getItem('name'),admin:localStorage.getItem('admin'),group:localStorage.getItem('group')})
		// 	this.$router.push({ path: '/' })
		// 	this.display='block'
		// } else {
		// 	this.display='none'

		// 	this.$router.push({ path: '/login' })
		// }
	},
	data() {
		return {
			locale: zhCN,
			menu: [],
			show: 0,
			display:'none'
		}
	},
	watch: {
		$route: {
			handler: function (route) {
				let token = localStorage.getItem('token')
				if (token == 'undefined' || token == null) {
					this.show = 0
					//if(route.path !='/login') this.$router.push({ path:'/login'})	
				} else {
					this.show = 1
				}
			},
			immediate: true,
		}
	}
};
</script>

<style>
body {
	background: #f0f2f5 !important
}

#app {
	min-height: 100%;
	background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
	background-repeat: no-repeat;
	background-position: center 110px;
	background-size: 100%;
	padding-top: 30px;
}

.qiqb-header {
	height: 70px;
	box-shadow: 0 1px 4px 0 rgb(0 21 41 / 12%);
	background: #fff !important;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
}

.qiqb-header-logo {
	width: 140px;
	height: 60px;
	display: flex;
	align-items: center;
	font-size: 24px;
}

.qiqb-header-logo img {
	width: 40px;
	height: 50px;
}

.qiqb-header-logo span {
	font-size: 16px;
	padding-left: 6px;
}

.qiqb-header-box {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
	flex-direction: row
}

.qiqb-header-box .ant-menu {
	border-bottom: none
}

.qiqb-header-menu {
	padding-top: 10px;
	flex: 1
}

.qiqb-header-right {
	height: 48px;
	display: flex;
	align-items: center;
	padding-top: 10px;
}

.page {
	max-width: 1200px;
	margin: 70px auto 50px
}

.ant-form-item {
	margin-bottom: 10px
}

.ant-form-explain {
	display: none
}

.config-form {
	width: 640px
}

.config-form-btn {
	margin-left: 133px
}

.qiqb-table {
	margin: 24px 0 0
}

.qiqb-table-search {
	margin-bottom: 16px;
	padding: 10px 24px 0;
	background: #fff
}

.qiqb-table-search-btn {
	display: flex;
	justify-content: flex-end;
	padding-top: 3px
}

.qiqb-table-item {
	padding: 0 24px;
	background: #fff
}

.qiqb-table-item .table-operator {
	height: 64px;
	display: flex;
	align-items: center
}

.qiqb-table-item .table-operator-title {
	flex: 1;
	font-size: 16px
}

.qiqb-table-item .table-item-img img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
}


.qiqb-from {
	background-color: #ffffff;
	margin: 24px 0;
	padding: 24px;
}

.ant-input {
	border-radius: 0;
}

.ant-select-selection {
	border-radius: 0;
}

.ant-input-number-input-wrap,
.ant-input-number-input {
	border-radius: 0;
}

.ant-input-number {
	width: 100% !important;
	border-radius: 0;
}

.ant-calendar-picker {
	width: 100% !important;
}

.ant-table-thead th {
	position: relative;
}

.ant-table-thead th::before {
	position: absolute;
	top: 50%;
	right: 0;
	width: 1px;
	height: 1.6em;
	background-color: rgba(0, 0, 0, 0.06);
	transform: translateY(-50%);
	transition: background-color 0.3s;
	content: '';
}

.ant-pagination-item {
	border-radius: 2px;
}

.ant-upload.ant-upload-select-picture-card>.ant-upload {
	padding: 0;
}

.ant-upload.ant-upload-select-picture-card {
	width: 100px;
	height: 100px;
	border-radius: 0;
}

.w-bgc {
	background-color: #fff;
	border-radius: 10px;
}

.flex-s {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flex-b {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-e {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.p-1 {
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.p-2 {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.p-3 {
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 30px;
	padding-bottom: 30px;
}

.p-4 {
	padding-left: 40px;
	padding-right: 40px;
	padding-top: 40px;
	padding-bottom: 40px;
}

.p-5 {
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 50px;
	padding-bottom: 50px;
}

.px-0 {
	padding-left: 0;
	padding-right: 0;
}

.px {
	padding-left: 5px;
	padding-right: 5px;
}

.px-1 {
	padding-left: 10px;
	padding-right: 10px;
}

.px-2 {
	padding-left: 20px;
	padding-right: 20px;
}

.px-3 {
	padding-left: 30px;
	padding-right: 30px;
}

.px-4 {
	padding-left: 40px;
	padding-right: 40px;
}

.px-5 {
	padding-left: 50px;
	padding-right: 50px;
}

.py-0 {
	padding-top: 0;
	padding-bottom: 0;
}

.py {
	padding-top: 5px;
	padding-bottom: 5px;
}

.py-1 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.py-2 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.py-3 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.py-4 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.py-5 {
	padding-top: 50px;
	padding-bottom: 50px;
}.m-0 {
	margin-left: 0;
	margin-right: 0;
	margin-top: 0;
	margin-bottom: 0;
}

.m {
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.m-1 {
	margin-left: 10px;
	margin-right: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.m-2 {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.m-3 {
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 30px;
	margin-bottom: 30px;
}

.m-4 {
	margin-left: 40px;
	margin-right: 40px;
	margin-top: 40px;
	margin-bottom: 40px;
}

.m-5 {
	margin-left: 50px;
	margin-right: 50px;
	margin-top: 50px;
	margin-bottom: 50px;
}

.mx-0 {
	margin-left: 0;
	margin-right: 0;
}

.mx {
	margin-left: 5px;
	margin-right: 5px;
}

.mx-1 {
	margin-left: 10px;
	margin-right: 10px;
}

.mx-2 {
	margin-left: 20px;
	margin-right: 20px;
}

.mx-3 {
	margin-left: 30px;
	margin-right: 30px;
}

.mx-4 {
	margin-left: 40px;
	margin-right: 40px;
}

.mx-5 {
	margin-left: 50px;
	margin-right: 50px;
}

.my-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.my {
	margin-top: 5px;
	margin-bottom: 5px;
}

.my-1 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.my-2 {
	margin-top: 20px;
	margin-bottom: 20px;
}

.my-3 {
	margin-top: 30px;
	margin-bottom: 30px;
}

.my-4 {
	margin-top: 40px;
	margin-bottom: 40px;
}

.my-5 {
	margin-top: 50px;
	margin-bottom: 50px;
}

.mt-0 {
	margin-top: 0;
}

.mt {
	margin-top: 5px;
}

.mt-auto {
	margin-top: auto;
}

.mt-1 {
	margin-top: 10px;
}

.mt-2 {
	margin-top: 20px;
}

.mt-3 {
	margin-top: 30px;
}

.mt-4 {
	margin-top: 40px;
}

.mt-5 {
	margin-top: 50px;
}

.mb-0 {
	margin-bottom: 0;
}

.mb {
	margin-bottom: 5px;
}

.mb-auto {
	margin-bottom: auto;
}

.mb-1 {
	margin-bottom: 10px;
}

.mb-2 {
	margin-bottom: 20px;
}

.mb-3 {
	margin-bottom: 30px;
}

.mb-4 {
	margin-bottom: 40px;
}

.mb-5 {
	margin-bottom: 50px;
}

.ml-0 {
	margin-left: 0;
}

.ml {
	margin-left: 5px;
}

.ml-auto {
	margin-left: auto;
}

.ml-1 {
	margin-left: 10px;
}

.ml-2 {
	margin-left: 20px;
}

.ml-3 {
	margin-left: 30px;
}

.ml-4 {
	margin-left: 40px;
}

.ml-5 {
	margin-left: 50px;
}

.mr-0 {
	margin-right: 0;
}

.mr {
	margin-right: 5px;
}

.mr-1 {
	margin-right: 10px;
}

.mr-2 {
	margin-right: 20px;
}

.mr-3 {
	margin-right: 30px;
}

.mr-4 {
	margin-right: 40px;
}

.mr-5 {
	margin-right: 50px;
}</style>