<template>
	<div class="qiqb-header">
		<div class="qiqb-header-box">
			<div class="qiqb-header-logo">
				<img src="../../assets/logo.png" />
				<!-- <span>健康</span> -->
			</div>
			<div class="qiqb-header-menu">
				<a-menu mode="horizontal">
					<a-menu-item key="0"><router-link to="/">首页</router-link></a-menu-item>
					<a-menu-item key="1">
						<router-link to="/user">用户档案</router-link>
					</a-menu-item>
					<a-menu-item key="2">
						<router-link to="/log">下发日志</router-link>
					</a-menu-item>
					<a-menu-item key="31">
						<router-link to="/target1">算法设置</router-link>
					</a-menu-item>
					<!-- <a-sub-menu key="31" title="模型报告">
						<a-menu-item key="31">
							<router-link to="/target1">单项指标</router-link>
						</a-menu-item>
						<a-menu-item key="32">
							<router-link to="/target2">综合指标</router-link>
						</a-menu-item>
					</a-sub-menu> -->
					<a-sub-menu key="4" title="系统设置">
						<a-menu-item key="41">
							<router-link to="/set1">既往病史</router-link>
						</a-menu-item>
						<a-menu-item key="42">
							<router-link to="/set2">遗传病史</router-link>
						</a-menu-item>
						<!-- <a-menu-item key="43">
							<router-link to="/set3">项目清单</router-link>
						</a-menu-item> -->
						<a-menu-item key="44">
							<router-link to="/set4">体验报告模板</router-link>
						</a-menu-item>
						<!-- <a-menu-item key="45">
							<router-link to="/set5">员工账号</router-link>
						</a-menu-item> -->
					</a-sub-menu>
				</a-menu>
			</div>
			<div class="qiqb-header-right">
				<a-dropdown>
					<a class="ant-dropdown-link" @click.prevent>{{ username }}</a>
					<template #overlay>
						<a-menu>
							<!-- <a-menu-item>
								<a @click="pass">修改密码</a>
							</a-menu-item> -->
							<a-menu-item>
								<a @click="logout">退出登录</a>
							</a-menu-item>
						</a-menu>
					</template>
				</a-dropdown>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			username: localStorage.getItem('name')
		}
	},
	props: {
		item: Object,
		default: function () {
			return {}
		}
	},
	mounted() {
		this.username = localStorage.getItem('name')
	},
	beforeCreate() {
		this.username = localStorage.getItem('name')

	},
	methods: {
		logout() {
			localStorage.clear()
			this.$router.push({ path: '/login' })
		}
	}
}
</script>
